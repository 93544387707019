/* You can add global styles to this file, and also import other style files */
@import "../../../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import '../../../../node_modules/leaflet/dist/leaflet.css';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500';

body {
  margin:0;
  font-family: Roboto, sans-serif;
  padding-top:48px;
  background: #fafafa;
}

.component-header h2 {
  font-weight: 300 !important;
  padding: 18px 25px;
  font-size: 20px;
  color: #fff;
  max-width: 1000px;
  margin: 8px auto 0 !important;
}

mat-toolbar.mat-primary {
    background: #2d3258 !important;
}

.logo {
  height: 48px;
  border: 8px solid #ffffff;
  margin-right: 20px;
}
